import {Box, Button, Stack, TextField, Typography, useTheme} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import ReservationContext from "../../context/ReservationContext";
import {thousandSeparator} from "../../utils/utils";
import {format} from "date-fns";

const Selected = () => {
    const {selected, discount, price, getCoupon, deleteCouponCode} = useContext(ReservationContext)
    const isDateValid = (date) => !isNaN(date)
    const hasValidData = dateList => dateList?.every(d => d) && dateList?.every(d => isDateValid(d))
    const [code, setCode] = useState('')
    // const [pr, setPr] = useState(price)
    const theme = useTheme()

    // useEffect(() => {
    //     setPr(price)
    //     console.log(price)
    // }, [price])

    const showSelectedDate = (selectedDate) => {
        if (hasValidData(selectedDate)) return format(selectedDate[0], 'yyyy-MM-dd') + ' - ' + format(selectedDate[1], 'yyyy-MM-dd')
        return '-'
    }
    const showPrice = (price) => {
        const p =  price?.price || price?.full_price
        if (p) return thousandSeparator(p) + ' Ft'
        return '-'
    }

    const couponCodeActive = () => Object.keys(discount).length !== 0

    const showCouponCode = () => {
        if (!couponCodeActive()) return ''
        if (discount?.minus) return `-${thousandSeparator(discount.minus)} Ft`
        return `-${discount?.percent} %`
    }

    const canAddCouponCode = () => {
        return selected?.apartment && selected?.selectedDate?.every(e => e)
    }

    return <div className='white-card'>
        <Stack spacing={2}>
            <Typography variant='headline3'>Összesítő</Typography>
            <Typography>Kiválasztott apartman: {selected?.apartment?.name}</Typography>
            <Typography>Kiválasztott dátum: {showSelectedDate(selected?.selectedDate)}</Typography>
            {couponCodeActive() ? <Stack direction='row' spacing={1}>
                <Typography variant='body1'>Ár: </Typography>
                <Typography variant='overlineBody1'>{thousandSeparator(price?.full_price)} Ft</Typography>
                <Typography variant='body1'>{thousandSeparator(price?.price)} Ft</Typography>
            </Stack> : <Typography variant='body1'>Ár: {showPrice(price)}</Typography>}
            <Box sx={{border: `1px solid ${theme.palette.primary[500]}`, borderRadius: 3, padding: 1}}>
                <Typography variant='body1'>A szállásdíj nem tartalmazza az idegenforgalmi adót, amely 600 Ft/fő/éj (18 éven aluliaknak díjmentes), melyet a helyszínen érkezéskor készpénzben szükséges rendezni.</Typography>
            </Box>
            {!couponCodeActive() ? <Stack direction='row' spacing={1} alignItems='center'>
                <TextField value={code} onChange={e => setCode(e.target.value)} disabled={!canAddCouponCode()}
                           variant='standard'/>
                <Button onClick={() => getCoupon(code)} disabled={!canAddCouponCode()} size='small' variant='outlined'>Kuponkód
                    hozzáadása</Button>
            </Stack> : <Stack>
                <Button onClick={deleteCouponCode} size='small' variant='outlined'>Kuponkód törlése</Button>
            </Stack>}
        </Stack>
    </div>
}

export default Selected